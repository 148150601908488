@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  height: 1px;
  width: 100%;
  margin: 32px 0;
  background-color: $gray;
  opacity: 0.3;
}
