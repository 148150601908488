@import 'src/styles/variables';
@import 'src/styles/mixins';

.place {
  font-weight: 700;
  color: $gray;
  white-space: nowrap;
}

.time {
  color: $black;
}

.restriction {
  color: $gray;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;

  margin-bottom: 16px;
  max-width: 235px;
}

.workPeriod {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba($color: $gray, $alpha: 0.3);

  &Title {
    font-weight: 700;
    color: $black;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    margin-bottom: 16px;

    @include vp-767 {
      margin: 16px 0;
      cursor: pointer;
    }

    &Icon {
      display: none;

      @include vp-767 {
        display: block;
        margin-left: auto;
      }
    }
  }

  &List {
    display: grid;
    grid-template-columns: repeat(auto-fill, 170px);
    gap: 74px;
    row-gap: 20px;

    @include vp-767 {
      max-height: 0;
      overflow: hidden;
      opacity: 0;
      transition: all 0.2s ease-out;

      &Opened {
        max-height: 100%;
        opacity: 1;
        transition: opacity 0.5s ease-out;
      }
    }
  }
}
