@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  position: absolute;
  left: 0;
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0 16px;

  &Light {
    position: relative;
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}

.icon {
  stroke: $black;
  transition: all 0.3s;
  cursor: pointer;
}

.search {
  width: 100%;
  padding: 0;
  border: none;
  background-color: transparent;
  height: 100%;
  outline: none;
  color: $black;
  @include text-16;
}

.container {
  width: 100vw;
  padding: 0;
  position: absolute;
  top: 44px;
  left: 0;
  background-color: $white;
  transition: all 0.3s;

  &Active {
    padding: 12px 16px;
  }

  @include vp-767 {
    left: -16px;
  }
}

.searchWrapper {
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  background-color: $light-gray;
  z-index: 5;
  padding: 0 12px;

  width: 100%;
  outline: none;
  border-radius: 10px;
  gap: 10px;
  cursor: default;
  max-height: 0px;
  overflow: hidden;

  &Active {
    max-height: 40px;
    padding: 9px 12px;
    outline: 2px solid $primary;

    .clear {
      max-height: 24px;
    }
  }
}

.clear {
  max-height: 0;
  transition: all 0.3s;
  cursor: pointer;
}

.results {
  background-color: $white;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 12px;
  border-radius: 0px 0px 24px 24px;
  min-height: 140px;
  padding: 24px;
  z-index: -1;
  animation: show 0.3s;

  &Wrapper {
    width: 100%;
    padding: 0 4px;

    &Light {
      position: absolute;
      top: 100px;
      left: -16px;
      width: 100vw;
      padding: 0;
    }
  }

  @keyframes show {
    0% {
      opacity: 0;
    }
    99% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

button.resultsButton {
  padding: 0;
}

.searchButton {
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $black;
  border-radius: 50px;
  cursor: pointer;
  z-index: 5;
  max-width: 36px;
  margin-bottom: 16px;
}
