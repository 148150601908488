@import 'src/styles/variables';
@import 'src/styles/mixins';

.arrowLinkIcon {
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  width: fit-content;
  height: fit-content;

  background: transparent;
  transition: all 0.3s;
  transform: rotate(90deg);
}

.static {
  transform: rotate(0);
}

.isOpen {
  cursor: default;
  transform: rotate(-90deg);
  pointer-events: none;
}
